import { notify } from 'notifications';
import { ColGroupDef, ValueSetterParams, ColDef } from 'ag-grid-community';
import { ManageTaskPriorityModalActions } from 'pages/production/controllers/manage-task-priority.controller';
import { DisplayRangeType } from 'pages/production/controllers/production-filters-controller/types';
import { agGridGroupedColumnDefinitions, TASKS_PER_PAGE, valueOptionsSelectedOptions } from 'pages/tasks/constants';
import {
  getPinnedSide,
  getColumnWidth,
  filterValidFields,
  checkHiddenColumn,
  generateRequestBody,
  getFormattedFilters,
  reorderColumnsByFieldOrder,
  getActiveFilters,
} from 'pages/tasks/tasks.helpers';
import { ColumnSizesT, ManageSelectOrDeselectAllTasksArgs, TasksFiltersEnum, TasksFiltersT } from 'pages/tasks/types/types';
import { AppState, GetStateFunction } from 'redux/store';
import { ProductionTaskService } from 'services/production-task.service';
import { ShowCompletedPeriodEnum } from 'services/production-workflow.model';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import {
  SelectedTask,
  TaskTableModel,
  TaskTableFilters,
  PerformerItem,
  SuccessWebsocketResponseForTasksTableT,
  WebsocketResponseMessageForTaskTableT,
} from 'services/task-table.model';
import { TaskTableService } from 'services/task-table.service';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';
import { StateController } from 'state-controller';
import { TaskTypeEnum, WebsocketEvent } from 'types/common-enums';
import { IdName, PaginationData, SortOrderOption } from 'types/common-types';
import { UserService } from 'services/user.service';
import { PaginateResponse } from 'types/paginate-response';
import { PriorityEnum } from 'types/priority-enums';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { debounce } from 'utils/debounce';
import { TaskAssigmentService } from 'services/task-assigment.service';
import { AssignmentUser, GetTasksByFiltersArgs, HandleAssignTasksIdsArgs } from 'pages/tasks/types/function-types';
import { UserShortModel, User } from 'services/user.model';
import { getAllTasksOnTheScreen, prepareRequestBodyForBulkAssignUsers, updateTasksFromWsResponse } from 'pages/tasks/helpers';
import { UNASSIGNED, PAGE_SIZE } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import { TasksLaunchingProgressActions } from 'pages/tasks/components/task-assign-users-progress-modal/task-assign-users-progress-modal.controller';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';

export type TasksState = {
  isLoading: boolean;
  editTaskId: string;
  isFetching: boolean;
  users: Array<User>;
  assignTaskIds: string[];
  filters: TasksFiltersT;
  columnSizes: ColumnSizesT;
  selectedTasks: SelectedTask[];
  isInvalidSlotAssignment: boolean;
  isAccessDeniedModalOpen: boolean;
  activeFilters: TasksFiltersEnum[];
  atLeastOneFilterSelected: boolean;
  isAssignUsersModalOpened: boolean;
  is_assignment_in_progress: boolean;
  isEditBasicRewardModalOpened: boolean;
  columnOrder: TasksFiltersEnum[] | null;
  groupedColumnDefinition: ColGroupDef[];
  tasks: PaginateResponse<TaskTableModel>;
  performer: {
    isLoading: Record<number, boolean>;
    options: Record<number, PerformerItem[]>;
    value: Record<number, PerformerItem | null>;
  };
  activeColumn: TasksFiltersEnum | '';
  paginationPerformers: Record<number, PaginationData>;
  selectedPerformersToMultiAssign: Record<number, string | null>;
};

const tasksDefaultState: TasksState = {
  tasks: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      next: 0,
      perPage: 0,
      prev: 0,
      total: 0,
    },
  },
  paginationPerformers: {},
  editTaskId: '',
  columnOrder: [],
  activeFilters: [],
  activeColumn: '',
  isLoading: false,
  isFetching: false,
  columnSizes: null,
  selectedTasks: [],
  groupedColumnDefinition: [],
  isAccessDeniedModalOpen: false,
  atLeastOneFilterSelected: false,
  isAssignUsersModalOpened: false,
  is_assignment_in_progress: false,
  isEditBasicRewardModalOpened: false,
  selectedPerformersToMultiAssign: {},
  users: [],
  performer: {
    isLoading: {},
    value: {
      0: {
        id: UNASSIGNED,
        name: 'Unassigned',
        avatar: '',
        lastName: '',
        firstName: '',
      },
    },
    options: [],
  },
  assignTaskIds: [],
  isInvalidSlotAssignment: false,
  filters: {
    filters: {
      // Production
      [TasksFiltersEnum.ProductionDeadline]: { value: [null, null] },
      [TasksFiltersEnum.ProductionStatus]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'To Do', id: ProductionStatusEnum.To_Do },
          { name: 'Stopped', id: ProductionStatusEnum.Stopped },
          { name: 'In Progress', id: ProductionStatusEnum.In_Progress },
          { name: 'Done', id: ProductionStatusEnum.Done },
          { name: 'From stock', id: ProductionStatusEnum.From_Stock },
          { name: 'Canceled', id: ProductionStatusEnum.Canceled },
        ],
      },
      [TasksFiltersEnum.RootProductionDeadline]: { value: [null, null] },

      [TasksFiltersEnum.ShowCompleted]: {
        radioValue: ShowCompletedPeriodEnum.Some,
        value: { id: '1', name: '1 day' },
        options: [
          { id: '1', name: '1 day' },
          { id: '3', name: '3 days' },
          { id: '7', name: '7 days' },
          { id: '30', name: '30 days' },
          { id: '90', name: '90 days' },
        ],
      },

      // Task
      [TasksFiltersEnum.IsInQueue]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.IsFailed]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.FailedAt]: { value: [null, null] },
      [TasksFiltersEnum.TaskType]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Workflow', id: TaskTypeEnum.Workflow },
          { name: 'Additional', id: TaskTypeEnum.Additional },
        ],
      },
      [TasksFiltersEnum.TaskStatus]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Reopened', id: TaskStatusEnum.Reopened },
          { name: 'In Progress', id: TaskStatusEnum.In_Progress },
          { name: 'On hold', id: TaskStatusEnum.On_Hold },
          { name: 'To Do', id: TaskStatusEnum.To_Do },
          { name: 'Blocked', id: TaskStatusEnum.Blocked },
          { name: 'Done', id: TaskStatusEnum.Done },
          { name: 'Canceled', id: TaskStatusEnum.Canceled },
        ],
      },
      [TasksFiltersEnum.ReportingPeriod]: { value: [null, null] },
      [TasksFiltersEnum.TaskPriority]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: PriorityEnum.Highest, id: PriorityEnum.Highest },
          { name: PriorityEnum.High, id: PriorityEnum.High },
          { name: PriorityEnum.Medium, id: PriorityEnum.Medium },
          { name: PriorityEnum.Low, id: PriorityEnum.Low },
          { name: PriorityEnum.Lowest, id: PriorityEnum.Lowest },
        ],
      },
      [TasksFiltersEnum.ReasonForFailure]: valueOptionsSelectedOptions,

      // Assignment
      [TasksFiltersEnum.Assignee]: valueOptionsSelectedOptions,
      [TasksFiltersEnum.AssigneeType]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Manual', id: AssignmentType.Manual },
          { name: 'Automatic', id: AssignmentType.Auto },
          { name: 'Self assignment', id: AssignmentType.Self_Assignment },
        ],
      },

      // Warnings
      [TasksFiltersEnum.AssigneeRequired]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.TimeLimitExceeded]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
    },
    queries: {
      // Product
      [TasksFiltersEnum.ProductName]: '',
      [TasksFiltersEnum.ProductVersion]: 0,
      [TasksFiltersEnum.ProductVariant]: '',
      [TasksFiltersEnum.RootProductName]: '',
      [TasksFiltersEnum.RootProductVersion]: 0,
      [TasksFiltersEnum.RootProductVariant]: '',
      [TasksFiltersEnum.ProductConfiguration]: '',
      [TasksFiltersEnum.RootProductConfiguration]: '',

      // Production
      [TasksFiltersEnum.ProductionKey]: '',
      [TasksFiltersEnum.RootProductionKey]: '',

      // Order
      [TasksFiltersEnum.Client]: '',
      [TasksFiltersEnum.OrderKey]: '',
      [TasksFiltersEnum.PrimaryClient]: '',
      [TasksFiltersEnum.ExternalOrderNumber]: '',
      [TasksFiltersEnum.MarketPlaceOrderNumber]: '',

      // Task
      [TasksFiltersEnum.TaskKey]: '',
      [TasksFiltersEnum.TaskName]: '',
      [TasksFiltersEnum.ReasonForFailure]: '',

      // Assignment
      [TasksFiltersEnum.AssigneePosition]: '',
      [TasksFiltersEnum.AssigneeDepartment]: '',
    },
    pins: {
      left: [],
      right: [],
    },
    sort: {
      orderBy: [],
      orderOption: SortOrderOption.Ascending,
    },
  },
};

const stateController = new StateController<TasksState>('TASKS', tasksDefaultState);

// Abort controllers
let getTasksByFiltersAbortController: AbortController | null = null;

export class TasksActions {
  static initTasksPage() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isLoading: true }));

      await dispatch(TasksActions.getTableFilters());
      await dispatch(TasksActions.checkIfAtLeastOneFilterSelected());
      await dispatch(TasksActions.getTasksByFilters({}));

      dispatch(stateController.setState({ isLoading: false }));
    };
  }

  static loadMoreTasks() {
    return (dispatch, getState: GetStateFunction) => {
      const { meta, data } = getState().tasks.tasks;

      if (data.length >= meta.total) return;

      dispatch(TasksActions.getTasksByFilters({ skip: meta.next, isFetching: true }));
    };
  }

  static getTasksByFilters({
    skip = 0,
    take = TASKS_PER_PAGE,
    isFetching = false,
    sorting = false,
    filterChanged = false,
  }: GetTasksByFiltersArgs) {
    return async (dispatch, getState: GetStateFunction) => {
      const {
        tasks: { data },
        filters,
        columnOrder,
        columnSizes,
      } = getState().tasks;

      if (getTasksByFiltersAbortController) {
        getTasksByFiltersAbortController.abort();
      }

      getTasksByFiltersAbortController = new AbortController();
      const { signal } = getTasksByFiltersAbortController;

      try {
        dispatch(stateController.setState({ isLoading: !isFetching, isFetching }));

        const fields = columnOrder?.length ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        const response = await TaskTableService.getAllTask(body, { skip, take }, signal);

        dispatch(
          stateController.setState({
            tasks: {
              ...response,
              data: filterChanged || sorting ? response.data : [...data, ...response.data],
            },
          }),
        );
      } catch (error) {
        if (axios.isCancel(error)) return;

        notify.error(error.message);
      } finally {
        dispatch(stateController.setState({ isLoading: false, isFetching: false }));
        getTasksByFiltersAbortController = null;
      }
    };
  }

  static getTableFilters() {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));

        const filtersData: TaskTableFilters | undefined = await UserService.getTaskTableFilters();
        const sortedColumnDefinitions = reorderColumnsByFieldOrder(agGridGroupedColumnDefinitions, filtersData.fields);

        const modified = sortedColumnDefinitions.map((group) => ({
          ...group,
          children: group.children.map((column: ColDef) => ({
            ...column,
            pinned: getPinnedSide(column, filtersData.pins),
            width: getColumnWidth(column.field as TasksFiltersEnum, filtersData.sizes),
            hide: checkHiddenColumn(column.field as TasksFiltersEnum, filtersData.fields),
            sort: column.field === filtersData.sort?.orderBy?.[0] && filtersData.sort?.orderOption,
            // Unfortunately this is how updates should happen. If you try to use onCellValueChanged
            // Ag-Grid will be mutating your original state and not via dispatch but like plane object mutations
            valueSetter: (params: ValueSetterParams<TaskTableModel>) =>
              dispatch(TasksActions.updateTask(params.data.id, { [params.colDef.field as string]: params.newValue })),
          })),
        }));

        const { filters } = getState().tasks.filters;
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnOrder: filtersData.fields || null,
            columnSizes: filtersData.sizes || null,
            groupedColumnDefinition: modified,
            activeFilters: getActiveFilters(filtersData),
            filters: {
              ...prev.filters,
              pins: {
                ...prev.filters.pins,
                ...filtersData.pins,
              },
              filters: {
                ...prev.filters.filters,
                ...getFormattedFilters(filtersData.filters, filters),
              },
              queries: {
                ...prev.filters.queries,
                ...filtersData.queries,
              },
              sort: {
                orderBy: filtersData.sort?.orderBy,
                orderOption: filtersData.sort?.orderOption,
              },
            },
          })),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      } finally {
        dispatch(stateController.setState({ isLoading: false, isFetching: false }));
      }
    };
  }

  static onColumnMoved(newColumnOrder: TasksFiltersEnum[]) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnOrder: newColumnOrder,
          })),
        );

        const { filters, columnSizes } = getState().tasks;
        const fields = filterValidFields(newColumnOrder);
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static onColumnChecked(newColumnOrder: TasksFiltersEnum[], isColumnVisible: boolean) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnOrder: newColumnOrder,
          })),
        );

        const { filters, columnSizes } = getState().tasks;
        const fields = filterValidFields(newColumnOrder);
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);

        if (isColumnVisible) {
          await dispatch(
            TasksActions.getTasksByFilters({ skip: 0, take: TASKS_PER_PAGE, isFetching: true, filterChanged: true }),
          );
        }
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static onColumnPinned(side: 'left' | 'right' | null, columnName: TasksFiltersEnum) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const { left, right } = getState().tasks.filters.pins;
        let pinsLeft = [...left];
        let pinsRight = [...right];

        if (side === 'left') {
          pinsLeft = [...pinsLeft, columnName];
        } else if (side === 'right') {
          pinsRight = [...pinsRight, columnName];
        } else {
          pinsLeft = pinsLeft.filter((i) => i !== columnName);
          pinsRight = pinsRight.filter((i) => i !== columnName);
        }

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              pins: {
                left: pinsLeft,
                right: pinsRight,
              },
            },
          })),
        );

        const { filters, columnOrder, columnSizes } = getState().tasks;
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static onSortChanged(columnName: TasksFiltersEnum | null, sortOrder: SortOrderOption | null) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              sort: {
                orderBy: [columnName],
                orderOption: sortOrder,
              },
            },
          })),
        );

        const { filters, columnOrder, columnSizes, tasks } = getState().tasks;
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);

        dispatch(TasksActions.getTasksByFilters({ isFetching: true, sorting: true, take: tasks.data.length }));
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  static onSizeChanged(columnName: TasksFiltersEnum, newSize: number) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnSizes: {
              ...prev.columnSizes,
              [columnName]: newSize,
            },
          })),
        );

        const { filters, columnOrder, columnSizes } = getState().tasks;
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static changeTaskDisplayRange(value: Partial<DisplayRangeType>) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            filters: {
              ...prev.filters.filters,
              [TasksFiltersEnum.ShowCompleted]: {
                ...prev.filters.filters[TasksFiltersEnum.ShowCompleted],
                ...value,
              },
            },
          },
        })),
      );

      dispatch(TasksActions.getTasksByFilters({ isFetching: true, filterChanged: true }));
    };
  }

  static setFilters(values: Partial<TasksFiltersT>, extendExistingValue?: boolean) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          filters: (Object.keys(values) as [keyof Partial<TasksFiltersT>]).reduce((acc, item) => {
            const currentValue = values[item];
            const previousFilter = prevState.filters[item];

            // The "undefined" comparison is needed to allow empty string in search filter
            if (currentValue === undefined) {
              return { ...acc, [item]: previousFilter };
            }

            if (
              extendExistingValue ||
              typeof currentValue === 'boolean' ||
              (Array.isArray(currentValue) && typeof currentValue[0] === 'object')
            ) {
              return { ...acc, [item]: { ...previousFilter, value: currentValue } };
            }

            return { ...acc, [item]: { value: currentValue } };
          }, prevState.filters),
        })),
      );
    };
  }

  static updateTask(taskId: string, value: Partial<TaskTableModel>) {
    return async (dispatch) => {
      try {
        await ProductionTaskService.updateTask(taskId, value);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            tasks: {
              ...prev.tasks,
              data: prev.tasks.data.map((task) => {
                if (task.id === taskId) {
                  const updatedAssignee = value.assignee
                    ? task.assignee.map((currentAssignee) => {
                        if (currentAssignee.slot_id === value.assignee?.[0].slot_id) {
                          return value.assignee?.[0];
                        }

                        return currentAssignee;
                      })
                    : task.assignee;
                  const updatedSlots = value.task_slots
                    ? task.task_slots.map((currentSlots) => {
                        if (currentSlots.id === value.task_slots?.[0].id) {
                          return value.task_slots?.[0];
                        }
                        return currentSlots;
                      })
                    : task.task_slots;
                  return {
                    ...task,
                    ...value,
                    assignee: updatedAssignee,
                    task_slots: updatedSlots,
                  };
                }

                return task;
              }),
            },
          })),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static updateMultipleTaskPriority(taskIds: string[], newPriority: PriorityEnum) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            data: prev.tasks.data.map((task) => {
              if (taskIds.includes(task.id)) {
                return {
                  ...task,
                  priority: newPriority,
                };
              }

              return task;
            }),
          },
        })),
      );
    };
  }

  static assignUserToTask(taskId: string, user: UserShortModel, slotId: string, responsibilityId: string) {
    return async (dispatch) => {
      try {
        await TaskAssigmentService.assignUser(user.id, slotId);

        const assignee = {
          slot_id: slotId,
          task_responsibility_id: responsibilityId,
          taskAssignment: {
            user: {
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
              avatar_image_url: user.avatar_image_url,
            },
          },
        };

        const task_slots = {
          id: slotId,
          task_assignment_id: user.id,
          task_responsibility_id: responsibilityId,
        };

        dispatch(TasksActions.updateTask(taskId, { assignee: [assignee], task_slots: [task_slots] }));
        dispatch(TasksActions.triggerUpdateSelectedTaskSlot(taskId, slotId, false));
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static unassignUserFromTask(taskId: string, user: AssignmentUser, slotId: string) {
    return async (dispatch) => {
      try {
        await TaskAssigmentService.unassignUser(slotId);

        const task_slots = {
          id: slotId,
          task_responsibility_id: user.task_responsibility_id,
        };

        dispatch(
          TasksActions.updateTask(taskId, {
            assignee: [user],
            task_slots: [task_slots],
          }),
        );
        dispatch(TasksActions.triggerUpdateSelectedTaskSlot(taskId, slotId, true));
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static triggerUpdateSelectedTaskSlot(taskId: string, slotId: string, isUnAssignAction: boolean) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedTasks: prev.selectedTasks.map((task) => {
              if (task.id === taskId) {
                const updatedSlots = task.slots.map((slot) => {
                  return slot.slot_id === slotId ? { ...slot, isUnassigned: isUnAssignAction } : slot;
                });

                return {
                  ...task,
                  slots: updatedSlots,
                };
              }

              return task;
            }),
          })),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static copyTaskLink(taskId: string) {
    return async () => {
      const { origin } = window.location;
      const link = `${origin}/task/${taskId}`;

      await navigator.clipboard.writeText(link);

      notify.success('The link is copied');
    };
  }

  static onFilterChange(
    fieldName: TasksFiltersEnum,
    value: string | boolean | UserShortModel | null | DateRange<Dayjs>,
    id?: string,
  ) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters, columnOrder, columnSizes } = getState().tasks;

      const isFilterField = fieldName in filters.filters;
      const isQueryField = fieldName in filters.queries;

      const updatedFilters = { ...filters };

      if (isFilterField) {
        const currentSelectedOptions = filters.filters[fieldName].selectedOptions || [];

        const updatedSelectedOptions = currentSelectedOptions.includes(id)
          ? currentSelectedOptions.filter((option) => option !== id)
          : [...currentSelectedOptions, id];

        updatedFilters.filters = {
          ...filters.filters,
          [fieldName]: {
            ...filters.filters[fieldName],
            value,
            selectedOptions: updatedSelectedOptions,
          },
        };
      }

      if (isQueryField) {
        updatedFilters.queries = {
          ...filters.queries,
          [fieldName]: value,
        };
      }

      // for 'reason_for_failure'
      if (isFilterField && isQueryField) {
        const cleanedSelectedOptions = updatedFilters.filters[fieldName].selectedOptions?.filter((option) => option);

        updatedFilters.filters[fieldName] = {
          ...updatedFilters.filters[fieldName],
          value,
          selectedOptions: cleanedSelectedOptions,
        };
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: updatedFilters,
          activeColumn: fieldName,
          activeFilters: getActiveFilters(generateRequestBody(updatedFilters, columnOrder, columnSizes)),
        })),
      );

      debounce(async () => {
        const body = generateRequestBody(updatedFilters, columnOrder, columnSizes);

        await UserService.setTableFilters(body);

        await dispatch(TasksActions.getTasksByFilters({ isFetching: true, filterChanged: true }));
        await dispatch(TasksActions.checkIfAtLeastOneFilterSelected());
      }, 500);
    };
  }

  private static hasSelectedValues(filterValue: typeof valueOptionsSelectedOptions) {
    if (Array.isArray(filterValue.value)) {
      return filterValue.value.some((value) => value !== null);
    }

    return filterValue.selectedOptions && filterValue.selectedOptions.length > 0;
  }

  static checkIfAtLeastOneFilterSelected() {
    return (dispatch, getState: GetStateFunction) => {
      const { filters, queries } = getState().tasks.filters;

      const hasOptionsFilter = Object.entries(filters).some(([, filterValue]) =>
        TasksActions.hasSelectedValues(filterValue as typeof valueOptionsSelectedOptions),
      );

      const hasQueryFilter = Object.values(queries).some((queryValue) => queryValue !== '' && queryValue !== 0);

      const atLeastOneFilterSelected = hasOptionsFilter || hasQueryFilter;

      dispatch(stateController.setState({ atLeastOneFilterSelected }));
    };
  }

  static setFilterOptions(fieldName: TasksFiltersEnum, options: IdName[]) {
    return (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().tasks;

      const currentFilter = filters?.filters[fieldName] || {};

      dispatch(
        stateController.setState({
          filters: {
            ...filters,
            filters: {
              ...filters.filters,
              [fieldName]: {
                ...currentFilter,
                options,
              },
            },
          },
        }),
      );
    };
  }

  static openTaskInNewTab(taskId: string) {
    return () => {
      const { origin } = window.location;
      const link = `${origin}/task/${taskId}`;

      window.open(link, '_blank');
    };
  }

  static openRootOrMainProductionInNewTab(productionId: string | null) {
    return () => {
      if (!productionId) return;

      const { origin } = window.location;
      const link = `${origin}/production-workflow/${productionId}`;

      window.open(link, '_blank');
    };
  }

  static openManageTaskPriorityModal(productionId: string, taskId: string) {
    return async (dispatch) => {
      try {
        const production = await ProductionWorkflowService.getProductionWorkflowInfo(productionId);

        if (production) {
          dispatch(ManageTaskPriorityModalActions.openModal({ production, taskId }));
        }
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  // ====== Edit basic reward modal =====================================================================================================

  public static openEditBasicRewardModal(taskId: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isEditBasicRewardModalOpened: true,
          editTaskId: taskId,
        })),
      );
    };
  }

  public static closeEditBasicRewardModal() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isEditBasicRewardModalOpened: false,
          editTaskId: '',
        })),
      );
    };
  }

  // ====== Bulk actions assign user ====================================================================================================

  public static initPerformersForSingleTaskSlotAssignment() {
    return async (dispatch) => {
      try {
        const users = (await UserService.getAllUsers({ skip: 0, take: PAGE_SIZE })).data;
        dispatch(stateController.setState({ users }));
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  public static bulkAssignMultipleOrSingleUserToTasks(responsibleId?: string) {
    return async (dispatch, getState: () => AppState) => {
      const { selectedPerformersToMultiAssign, selectedTasks } = getState().tasks;
      const performers = responsibleId ? { responsibleId } : selectedPerformersToMultiAssign;
      const assignToManyTasksBody = prepareRequestBodyForBulkAssignUsers(performers, selectedTasks);

      if (assignToManyTasksBody.length === 0) return;

      try {
        await TaskTableService.bulkAssignUsersToTasks(assignToManyTasksBody);

        if (selectedTasks.length) {
          dispatch(TasksLaunchingProgressActions.showModalAndSetAssignUsersTasksCount(selectedTasks.length));
        }
      } catch (error) {
        notify.error(error.message);
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedTasks: [],
            didFiltersChange: false,
            selectedPerformersToMultiAssign: {},
            performer: {
              isLoading: {},
              value: {
                0: {
                  id: UNASSIGNED,
                  name: 'Unassigned',
                  avatar: '',
                  lastName: '',
                  firstName: '',
                },
              },
              options: [],
            },
          })),
        );
      }
    };
  }

  public static manageSelectOrDeselectAllTasks = ({ resetAll }: ManageSelectOrDeselectAllTasksArgs) => {
    return (dispatch, getState: () => AppState) => {
      const { selectedTasks } = getState().tasks;
      const { data } = getState().tasks.tasks;
      if (resetAll || selectedTasks.length) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedTasks: [],
            assignTaskIds: [],
          })),
        );
        return;
      }

      const tasks = getAllTasksOnTheScreen(data);
      const updateAssignTaskIds = tasks.map((task) => task.id);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedTasks: tasks,
          assignTaskIds: updateAssignTaskIds,
        })),
      );
    };
  };

  public static handleSelectDeselectTasks = (task: TaskTableModel) => {
    return (dispatch, getState: () => AppState) => {
      const { selectedTasks, tasks, assignTaskIds } = getState().tasks;
      const isSelected = selectedTasks.some((item) => item.id === task.id);
      const actualTask = tasks.data.find((t) => t.id === task.id);

      const updatedSelectedTasks = isSelected
        ? selectedTasks.filter((item) => item.id !== task.id)
        : [
            ...selectedTasks,
            {
              id: task.id,
              slots:
                actualTask?.task_slots.map((task_slot) => ({
                  slot_id: task_slot.id,
                  task_responsibility_id: task_slot.task_responsibility_id,
                  isUnassigned: !task_slot.task_assignment_id,
                })) || [],
              departmentIds: task.department_ids,
            },
          ];

      const updatedAssignTaskIds = isSelected ? assignTaskIds.filter((id) => id !== task.id) : [...assignTaskIds, task.id];

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedTasks: updatedSelectedTasks,
          assignTaskIds: updatedAssignTaskIds,
        })),
      );
    };
  };

  static openAssignUsersModal(value: boolean) {
    return (dispatch) => {
      dispatch(stateController.setState({ isAssignUsersModalOpened: value }));
    };
  }

  static onChangeAssignmentSlotModalValue(slotId: string, value: PerformerItem) {
    return (dispatch, getState: () => AppState) => {
      const { selectedPerformersToMultiAssign } = getState().tasks;
      const isIdAlreadyAssigned = Object.values(selectedPerformersToMultiAssign).includes(value.id);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedPerformersToMultiAssign: {
            ...prev.selectedPerformersToMultiAssign,
            [slotId]: value.id,
          },
          performer: {
            ...prev.performer,
            value: {
              ...prev.performer.value,
              [slotId]: value,
            },
          },
          isInvalidSlotAssignment: isIdAlreadyAssigned,
        })),
      );
    };
  }

  public static initPerformersForMultiTaskSlotAssignment(slotId: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { options } = getState().tasks.performer;
      if (options[slotId] && Object.keys(options[slotId]).length) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            performer: {
              ...prev.performer,
              isLoading: {
                ...prev.performer.isLoading,
                [slotId]: true,
              },
            },
          })),
        );

        const { data, meta } = await UserService.getAllUsers({ skip: 0, take: PAGE_SIZE });

        const newOptions = data.map((performer) => ({
          id: performer.id,
          name: `${performer.first_name} ${performer.last_name}`.trim(),
          avatar: performer.avatar_image_url,
          lastName: performer.last_name,
          firstName: performer.first_name,
        }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationPerformers: {
              ...prev.paginationPerformers,
              [slotId]: {
                total: meta.total,
                currentPage: meta.currentPage,
                perPage: meta.perPage,
                lastPage: meta.lastPage,
                next: meta.next,
              },
            },
            performer: {
              ...prev.performer,
              options: {
                ...prev.performer.options,
                [slotId]: newOptions,
              },
              isLoading: {
                ...prev.performer.isLoading,
                [slotId]: false,
              },
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            performer: {
              ...prev.performer,
              isLoading: {
                ...prev.performer.isLoading,
                [slotId]: false,
              },
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchPerformersForMultiTaskSlotAssignment(
    slotId: string,
    value: string = '',
    isScroll: boolean = false,
  ) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationPerformers: {
              ...prev.paginationPerformers,
              [slotId]: {
                ...prev.paginationPerformers[slotId],
                next: 0,
              },
            },
          })),
        );
      }
      const { paginationPerformers } = getState().tasks;
      if (isScroll && paginationPerformers[slotId].currentPage >= paginationPerformers[slotId].lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            performer: {
              ...prev.performer,
              isLoading: {
                ...prev.performer.isLoading,
                [slotId]: true,
              },
            },
          })),
        );
        debounce(async () => {
          const { data, meta } = await UserService.getAllUsers({
            skip: paginationPerformers[slotId].next,
            take: paginationPerformers[slotId].perPage,
            search: value.trim(),
          });
          const newOptions = data.map((performer) => ({
            id: performer.id,
            name: `${performer.first_name} ${performer.last_name}`.trim(),
            avatar: performer.avatar_image_url,
            lastName: performer.last_name,
            firstName: performer.first_name,
          }));

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              performer: {
                ...prev.performer,
                options: {
                  ...prev.performer.options,
                  [slotId]: isScroll ? [...(prev.performer.options[slotId] || []), ...newOptions] : newOptions,
                },
                isLoading: {
                  ...prev.performer.isLoading,
                  [slotId]: false,
                },
              },
              paginationPerformers: {
                ...prev.paginationPerformers,
                [slotId]: {
                  total: meta.total,
                  currentPage: meta.currentPage,
                  perPage: meta.perPage,
                  lastPage: meta.lastPage,
                  next: meta.next,
                },
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            performer: {
              ...prev.performer,
              isLoading: {
                ...prev.performer.isLoading,
                [slotId]: false,
              },
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  // ====== WS handlers ==================================================================================================================

  static handleAssignTasksIds = ({ value, removeLaunchedTaskIds }: HandleAssignTasksIdsArgs) => {
    return (dispatch) => {
      if (removeLaunchedTaskIds) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            assignTaskIds: prev.assignTaskIds.filter((launchingId) => !value.includes(launchingId)),
          })),
        );
      }
    };
  };

  static replaceUserInTaskSlot(taskId: string, user: SuccessWebsocketResponseForTasksTableT['user'], slotId: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            data: updateTasksFromWsResponse({
              id: taskId,
              slotId,
              user,
              tasks: prev.tasks,
            }).data,
          },
        })),
      );
    };
  }

  static handleWebsocketResponse = (message: WebsocketResponseMessageForTaskTableT) => {
    return (dispatch, getState: () => AppState) => {
      const { task_id, slot_id, user } = message;
      if (message.event === WebsocketEvent.TaskAssignmentFailed) {
        dispatch(
          TasksActions.handleAssignTasksIds({
            value: [task_id],
            removeLaunchedTaskIds: true,
          }),
        );
        return;
      }
      setTimeout(() => dispatch(TasksActions.replaceUserInTaskSlot(task_id, user, slot_id)), 500);
      dispatch(
        TasksActions.handleAssignTasksIds({
          value: [task_id],
          removeLaunchedTaskIds: true,
        }),
      );
      const updatedAssignTaskIds = getState().tasks.assignTaskIds;

      if (updatedAssignTaskIds.length === 0) {
        notify.success('Successfully updated');
        dispatch(TasksLaunchingProgressActions.hideModal());
      } else {
        dispatch(TasksLaunchingProgressActions.setTasksAssignUsersCount(updatedAssignTaskIds.length));
      }
    };
  };
}

export class TasksSelectors {
  public static isSelectedTask = (state: AppState, taskId: string): boolean => {
    return state.tasks.selectedTasks.some((selectedTask) => selectedTask.id === taskId);
  };

  public static canMassAssignUser = (state: AppState): boolean => {
    const { selectedTasks } = state.tasks;
    if (selectedTasks.length === 0) return false;
    const allSlotsEmpty = selectedTasks.every((task) => task.slots.every((slot) => slot.isUnassigned));
    const unassignedCounts = selectedTasks.map((task) => task.slots.filter((slot) => slot.isUnassigned).length);
    const countsEqual = unassignedCounts.every((count) => count === unassignedCounts[0]);
    return allSlotsEmpty && countsEqual;
  };

  public static canSingleOrMultiAssignUser = (state: AppState): boolean => {
    const { selectedTasks } = state.tasks;
    return selectedTasks.some((task) => task.slots.filter((slot) => slot.isUnassigned).length >= 2);
  };
}

export const tasksReducer = stateController.getReducer();
